import * as React from 'react';
import { useEffect, useRef } from 'react';
import { TeliaHeading, TeliaSelect } from '@teliads/components/react';
import { Grid, Layout } from '../components/Layout';
import { navigate, PageProps } from 'gatsby';
import { SmartWifiPage } from "../models/ContentfulTypes";
import { getUrlWithCountry } from "../utils";
import '../styles/smartWifiApplication.scss';

interface Props extends PageProps {
    pageContext: { page: SmartWifiPage; contentfulId: string; countryLabel: string };
}

interface PropsApplicationSelection {
    page: SmartWifiPage;
}

const SmartWifiApplicationApplicationSelection = ({page}: PropsApplicationSelection) => {
    return (
        <div className="smart-wifi-app-badges">
            {page.smartWifiApplicationReference?.appLinkAndroid &&
                <a href={page.smartWifiApplicationReference.appLinkAndroid}>
                    <img src="/app-store-badge-google-play.png"
                         alt="Get it on Google Play"/>
                </a>
            }
            {page.smartWifiApplicationReference?.appLinkIOs &&
                <a href={page.smartWifiApplicationReference.appLinkIOs}>
                    <img src="/app-store-badge-ios.svg"
                         alt="Download on the App Store"/>
                </a>
            }
            {(!page.smartWifiApplicationReference?.appLinkAndroid && !page.smartWifiApplicationReference?.appLinkIOs) &&
                <div>{page.noApplicationPlaceholderText}</div>
            }
        </div>
    )
}

const SmartWifiApplication = ({pageContext: {page, contentfulId, countryLabel}}: Props) => {
    const select = useRef<HTMLTeliaSelectElement>(null);

    const countrySelectOptions = page.countries?.map((val: string) => ({
        value: val,
        label: val,
        selected: val == countryLabel,
        disabled: false
    }));

    const onChange = async (e: any) => {
        await navigate(getUrlWithCountry(e.target.value, '/smartwifi'))
    };

    useEffect(() => {
        if (select && select.current) {
            select.current.addEventListener('change', onChange);
        }
    }, []);

    return (
        <Layout contentfulId={contentfulId} title={page.title} languageSelectionEnabled={false}>
            <Grid className="smart-wifi center">
                <div className="smart-wifi-container">
                    <TeliaHeading tag="h1" variant="title-300">
                        {page.title}
                    </TeliaHeading>

                    <div>
                        {page.paragraph}
                    </div>

                    <div className="smart-wifi-select">
                        <TeliaSelect label={page.selectCountryLabel}
                             options={countrySelectOptions}
                             title="Country"
                             ref={select}/>
                    </div>
                </div>

                <div className="smart-wifi-shadow-container">
                    <div>
                        <img src={page.appIcon.file.url}
                             alt={page.appIcon.title}
                             className="smart-wifi-app-icon"/>
                    </div>

                    <TeliaHeading tag="h2" variant="title-100">
                        {page.smartWifiApplicationReference?.applicationName}
                    </TeliaHeading>

                    <div>{page.smartWifiApplicationReference?.countryLabel}</div>

                    <SmartWifiApplicationApplicationSelection page={page}/>
                </div>
            </Grid>
        </Layout>
    );
};

export default SmartWifiApplication;
